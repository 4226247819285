define('web-app-frontend/models/project', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    assemblyIncluded: _emberData['default'].attr('string', { defaultValue: 'Unknown' }),
    assemblyTurnTime: _emberData['default'].attr('string'),
    clientState: _emberData['default'].attr('string', { defaultValue: 'projectInformation' }),
    commitOnSuccess: _emberData['default'].attr('boolean', { defaultValue: true }),
    creationDate: _emberData['default'].attr('string'),
    formalQuoteRequestedDate: _emberData['default'].attr('string'),

    customAssemblyQuote: _emberData['default'].attr('boolean'),
    customAssemblyNotes: _emberData['default'].attr('string'),
    customFabQuote: _emberData['default'].attr('boolean'),
    customFabNotes: _emberData['default'].attr('string'),
    customPartsQuote: _emberData['default'].attr('boolean'),
    isCustomPartsQuotingDone: _emberData['default'].attr('boolean'),
    isFormalQuote: _emberData['default'].attr('boolean'),
    isOfflineFabrication: _emberData['default'].attr('boolean'),
    scDealType: _emberData['default'].attr('string'),

    fabIncluded: _emberData['default'].attr('string', { defaultValue: 'Unknown' }),
    fabTurnTime: _emberData['default'].attr('string'),
    guidForDuplication: _emberData['default'].attr('string', { serialize: false }),
    isUserEditable: _emberData['default'].attr('boolean', { serialize: false }),
    modificationDate: _emberData['default'].attr('string'),
    onHold: _emberData['default'].attr('boolean'),
    onHoldReason: _emberData['default'].attr('string'),
    orderNumber: _emberData['default'].attr('string'),
    orderTotal: _emberData['default'].attr('number'),
    parentOrderNumber: _emberData['default'].attr('string'),
    partsIncluded: _emberData['default'].attr('string', { defaultValue: 'Unknown' }),
    projectGUID: _emberData['default'].attr('string'),
    projectName: _emberData['default'].attr('string'),
    totalCostPerBoard: _emberData['default'].attr('number'),
    promoCode: _emberData['default'].attr('string'),
    quotedPartsTotal: _emberData['default'].attr('number'),
    quotedPartsPerBoardTotal: _emberData['default'].attr('number'),
    materialsPrice: _emberData['default'].attr('number'),
    revision: _emberData['default'].attr('string'),
    sessionGUID: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    taxAmountString: _emberData['default'].attr('string'),
    totalDiscount: _emberData['default'].attr('number'),
    shippingChargeDollars: _emberData['default'].attr('number'),
    type: _emberData['default'].attr('string'),
    taxExemptionStatus: _emberData['default'].attr('string'),
    userCreditDetails: _emberData['default'].attr(),
    shippingAddressDetails: _emberData['default'].attr(),
    ownerDetails: _emberData['default'].attr(),
    // errors                    : DS.attr(),
    fabricationServiceDetails: _emberData['default'].attr(),
    assemblyServiceDetails: _emberData['default'].attr(),
    canUploadKits: _emberData['default'].attr('boolean'),

    selfExempt: _emberData['default'].attr('boolean'),
    salesTaxAmount: _emberData['default'].attr('number'),
    exemptStatus: _emberData['default'].attr('string'),
    exemptStatusDisplay: _emberData['default'].attr('string'),
    hubSpotConversationId: _emberData['default'].attr('string', { defaultValue: '' }),
    // isKittedBom               : DS.attr('boolean', { defaultValue: false }),

    // Associations
    assemblyDetails: _emberData['default'].belongsTo('quote-assembly', { async: false }),
    fabricationDetails: _emberData['default'].belongsTo('quote-fabrication', { async: false }),
    bomDetails: _emberData['default'].belongsTo('bom', { async: false }),

    order: _emberData['default'].belongsTo('order', { async: true }),
    projectFile: _emberData['default'].belongsTo('project-file', { async: true }),
    projectPromotion: _emberData['default'].belongsTo('project-promotion', { async: true }),
    shipping: _emberData['default'].belongsTo('project-shipping', { async: true }),
    timeLineNodes: _emberData['default'].hasMany('timeline-node', { async: true }),

    fabQuotingAvailable: true,

    totalAmountwithExemption: (function () {
      return this.get('orderTotal') - this.get('salesTaxAmount');
    }).property('salesTaxAmount', 'orderTotal'),

    assemblyIncludedBoolean: (function () {
      return this.get('assemblyIncluded').toLowerCase() === 'true';
    }).property('assemblyIncluded'),

    hasCustomQuotePendingApproval: (function () {
      return this.get('status') === 'Custom Quote Pending' || this.get('status') === 'Custom Quote Received';
    }).property('status'),

    hasCustomQuoteApproved: (function () {
      return this.get('status') === 'Custom Quote Confirmed';
    }).property('status'),

    formalQuote: (function () {
      return this.get('status') === 'Tracked Quote';
    }).property('status'),

    isUnConfirmedOrder: (function () {
      return this.get('status') === 'Order Placed' || this.get('status') === 'Order In Review';
    }).property('status'),

    hasPromoCode: (function () {
      return _ember['default'].isPresent(this.get('promoCode'));
    }).property('promoCode'),

    hasDiscount: (function () {
      return this.get('totalDiscount') > 0;
    }).property('totalDiscount'),

    fabIncludedBoolean: (function () {
      return this.get('fabIncluded').toLowerCase() === 'true';
    }).property('fabIncluded'),

    userRemovedFab: (function () {
      return this.get('fabIncluded') === 'False';
    }).property('fabIncluded'),

    partsIncludedBoolean: (function () {
      return this.get('partsIncluded').toLowerCase() === 'true';
    }).property('partsIncluded'),

    hasPartsAndNoFab: (function () {
      return this.get('partsIncludedBoolean') && !this.get('fabIncludedBoolean');
    }).property('fabIncludedBoolean', 'partsIncludedBoolean'),

    partsIncludedHasBeenSet: (function () {
      var fileIsPresentOrNA = true;
      if (this.get('partsIncludedBoolean') && !this.get('customPartsQuote')) {
        fileIsPresentOrNA = _ember['default'].isPresent(this.get('bomDetails.bomFileName'));
      }
      return fileIsPresentOrNA && this.get('partsIncluded') !== 'Unknown';
    }).property('partsIncluded', 'bomDetails.bomFileName'),

    assemblyTurnDays: (function () {
      if (this.get('assemblyDetails.turnDaysDisplay')) {
        var stringNum = this.get('assemblyDetails.turnDaysDisplay').split(' ')[0];
        if (stringNum === '24') {
          stringNum = 1;
        }
        if (stringNum === '48') {
          stringNum = 2;
        }
        return Number(stringNum);
      } else {
        return 0;
      }
    }).property('assemblyTurnTime'),

    fabricationTurnDays: (function () {
      if (this.get('fabricationDetails.turnDaysDisplay')) {
        var stringNum = this.get('fabricationDetails.turnDaysDisplay').split(' ')[0];
        return Number(stringNum);
      } else {
        return 0;
      }
    }).property('fabTurnTime'),

    totalTurnDays: (function () {
      // the additional day is the window given for 'fab handling'
      if (this.get('fabIncludedBoolean')) {
        return this.get('assemblyTurnDays') + this.get('fabricationTurnDays') + 1;
      } else {
        return this.get('assemblyTurnDays');
      }
    }).property('assemblyTurnTime', 'fabTurnTime'),

    hasAssemblyAndFab: (function () {
      return this.get('assemblyIncludedBoolean') && this.get('fabIncludedBoolean');
    }).property('assemblyIncluded', 'fabIncluded'),

    requiresKittedInformation: (function () {
      if (!this.get('partsIncludedBoolean') || !this.get('fabIncludedBoolean') || this.get('bomDetails.customerWillProvideParts')) {
        return true;
      } else {
        return false;
      }
    }).property('fabIncluded', 'partsIncluded', 'bomDetails.customerWillProvideParts'),

    needsToBeRequoted: (function () {
      var requote = false;

      if (this.get('isUserEditable') === true) {
        var today = moment();
        var lastChangeDate = moment(this.get('modificationDate'));
        requote = today.diff(lastChangeDate, 'days') >= 3 && this.get('partsIncludedHasBeenSet');
      }

      return requote;
    }).property('modificationDate'),

    estimatedShipping: (function () {
      return this.get('shippingChargeDollars');
    }).property('shippingChargeDollars'),

    hasShippingInformation: (function () {
      return !_ember['default'].isEmpty(this.get('shipping'));
    }).property('shipping'),

    originalTotal: (function () {
      return this.get('orderTotal') + this.get('totalDiscount');
    }).property('orderTotal', 'totalDiscount'),

    findTimelineNodeInProgress: (function () {
      // This will watch each of the projects timelineNodes for changes in completionDate
      // will set status of inprogress node.
      var findNodes = this.get('timeLineNodes');
      var objectInProgress;

      findNodes.then(function (nodes) {
        nodes = nodes.sortBy('sequence');
        nodes.setEach('inProgress', false);

        objectInProgress = nodes.filterBy('completionDate', null).get('firstObject');
        if (objectInProgress) {
          objectInProgress.set('inProgress', true);
        }
      });
    }).observes('timeLineNodes.@each.completionDate'),

    quoteFormIsIncomplete: (function () {
      return this.get('isUserEditable') === true;
    }).property('isUserEditable'),

    projectIsComplete: (function () {
      return this.get('isUserEditable') !== true;
    }).property('isUserEditable'),

    // Custom Quote Properties
    isNotFullCustomPartsQuote: (function () {
      //but could have few parts marked custom
      return !this.get('customPartsQuote') || this.get('customPartsQuote') && !_ember['default'].isEmpty(this.get('bomDetails'));
    }).property('customPartsQuote', 'bomDetails'),

    //PN : Removing custom parts flag for now so that it can be used for skip bom functionality
    //without affecting custom quote flag
    //isCustomQuote: function() {
    //  return this.get('customAssemblyQuote') || this.get('customFabQuote') || this.get('customPartsQuote');
    //}.property('customAssemblyQuote', 'customFabQuote', 'customPartsQuote'),
    isCustomQuote: (function () {
      return false;
    }).property('customAssemblyQuote', 'customFabQuote', 'customPartsQuote'),

    isNotCustomQuote: (function () {
      return !this.get('isCustomQuote');
    }).property('isCustomQuote'),

    // Parts States
    userRemovedParts: (function () {
      return this.get('partsIncluded') === 'False';
    }).property('partsIncluded'),

    isKittedBom: (function () {
      return this.get('bomDetails.isKittedBom');
    }).property('bomDetails.isKittedBom'),

    partsValidOrRemoved: (function () {
      return this.get('userRemovedParts') || this.get('bomDetails.noInvalidParts');
    }).property('userRemovedParts', 'bomDetails.noInvalidParts'),

    userSkippedOnlineQuoting: (function () {
      return this.get('partsIncludedBoolean') && this.get('customPartsQuote') && _ember['default'].isEmpty(this.get('bomDetails'));
    }).property('partsIncludedBoolean', 'customPartsQuote', 'bomDetails'),

    allPartsQuotedByUser: (function () {
      return this.get('partsIncluded') && !this.get('customPartsQuote') && this.get('bomDetails.bomLines.length') > 0;
    }).property('partsIncluded', 'customPartsQuote', 'bomDetails.bomLines.[]'),

    fewPartsQuotedByUser: (function () {
      return this.get('partsIncluded') && this.get('customPartsQuote') && this.get('bomDetails.bomLines.length') > 0;
    }).property('partsIncluded', 'customPartsQuote', 'bomDetails.bomLines.[]'),

    formattedFormalQuoteRequestedDate: (function () {
      return moment(this.get('formalQuoteRequestedDate')).format('MMMM Do, YYYY');
    }).property('formalQuoteRequestedDate'),

    assemblyFormalQuote: (function () {
      return this.get('isFormalQuote') && this.get('customAssemblyQuote');
    }).property('customAssemblyQuote', 'isFormalQuote'),

    isOfflineAssemblyQuote: (function () {
      return (this.get('status') === 'Saved Quote' || this.get('status') === 'New Quote' || this.get('status') === 'Order Placed' || this.get('status') === 'Tracked Quote' || this.get('status') === 'Order In Review') && this.get('scDealType') === 'OFFLINE';
    }).property('status', 'scDealType'),

    fabFormalQuote: (function () {
      return this.get('isFormalQuote') && this.get('customFabQuote');
    }).property('customFabQuote', 'isFormalQuote'),

    showFabSourcedBySCNote: (function () {
      return this.get('fabFormalQuote') || this.get('fabricationDetails.isOfflineFabrication');
    }).property('fabricationDetails.isOfflineFabrication', 'fabFormalQuote'),

    partsFormalQuote: (function () {
      return this.get('isFormalQuote') && this.get('customPartsQuote');
    }).property('customPartsQuote', 'isFormalQuote'),

    showCustomPartsQuotingDoneMessage: (function () {
      return this.get('customPartsQuote') && this.get('isCustomPartsQuotingDone');
    }).property('isCustomPartsQuotingDone', 'customPartsQuote'),

    showCustomFabQuotingDoneMessage: (function () {
      return this.get('customFabQuote') && this.get('fabricationDetails.quoteTotal') !== 0;
    }).property('customFabQuote', 'fabricationDetails.quoteTotal'),

    orderedBySunstone: (function () {
      return this.get('scDealType') === "SUNSTONE_RFQ" || this.get('scDealType') === "SUNSTONE_BUNDLED";
    }).property('scDealType')
  });
});