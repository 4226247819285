define("web-app-frontend/routes/about/sponsorship", ["exports", "ember", "web-app-frontend/mixins/reset-scroll"], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports["default"] = _ember["default"].Route.extend(_webAppFrontendMixinsResetScroll["default"], {
    controllerName: "about",
    titleToken: "Sponsorship",

    headTags: [{
      type: "meta",
      tagId: "meta-description",
      attrs: {
        name: "description",
        content: "The Screaming Sponsorship program helps college and non-profit teams push the technology envelope by providing professional assembly expertise."
      }
    }],

    setupController: function setupController(controller) {
      controller.set("indexName", "sponsorship");
    },

    afterModel: function afterModel() {
      this._super.apply(this, arguments); // Use _super to call the parent class's init
      this.loadHubspotForm(); // Schedule the form loading
    },

    loadHubspotForm: function loadHubspotForm() {
      var script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/8698768.js";
      script.type = "text/javascript";
      script.charset = "utf-8";

      // Below lines will use when we call script form.
      script.onload = function () {
        if (window.hbspt) {
          window.hbspt.forms.create({
            target: "#hubspot-form-container"
          });
        }
      };

      script.onerror = function () {
        console.error("Failed to load HubSpot script");
      };

      document.body.appendChild(script);
    }
  });
});