define("web-app-frontend/templates/components/quote-parts", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 16
              },
              "end": {
                "line": 12,
                "column": 16
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  View\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 16
              },
              "end": {
                "line": 14,
                "column": 16
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  Edit\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 12
              },
              "end": {
                "line": 23,
                "column": 12
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "ml5 mr5");
            var el2 = dom.createTextNode("or");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "ib");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "underline");
            dom.setAttribute(el2, "data-toggle", "tooltip");
            dom.setAttribute(el2, "data-placement", "top");
            dom.setAttribute(el2, "title", "Select this option if you would like us to quote your parts for you.");
            var el3 = dom.createTextNode("Quote BOM");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [2, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element10);
            return morphs;
          },
          statements: [["element", "action", ["sourceBom"], [], ["loc", [null, [22, 35], [22, 57]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 10
              },
              "end": {
                "line": 29,
                "column": 10
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "mt10");
            var el2 = dom.createTextNode("We'll get back to you with parts pricing after you request a formal quote during checkout.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 10
              },
              "end": {
                "line": 32,
                "column": 10
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "mt20");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-exclamation-triangle orange");
            dom.setAttribute(el2, "data-original-title", "");
            dom.setAttribute(el2, "title", "");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("We are currently experiencing component related delays due to an extreme worldwide parts shortage.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Quoted parts may go out of stock in the brief time it takes to forward your order to distributors and some parts may take additional time to quote. Though we work closely with our network of distributors to fulfill your order quickly, this shortage is impacting project start dates. Turn times begin after all components arrive. ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "https://blog.screamingcircuits.com/2018/06/top-5-ways-to-mitigate-component-availability-problems.html");
            dom.setAttribute(el2, "target", "_blank");
            var el3 = dom.createTextNode("Read more about how you can help");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(".");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 36,
              "column": 4
            }
          },
          "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bordered bg-white mb20");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "padded");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "float-right");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4, "class", "edit-section ib");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "class", "underline editBomModal");
          dom.setAttribute(el5, "data-toggle", "tooltip");
          dom.setAttribute(el5, "data-placement", "top");
          dom.setAttribute(el5, "title", "Select this option to view your uploaded BOM.");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "ml5 mr5");
          var el5 = dom.createTextNode("or");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4, "class", "ib");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "class", "underline");
          dom.setAttribute(el5, "data-toggle", "tooltip");
          dom.setAttribute(el5, "data-placement", "top");
          dom.setAttribute(el5, "title", "Select this option if you would like to upload a new BOM.");
          var el6 = dom.createTextNode("Upload New Bom");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          dom.setAttribute(el3, "class", "open-sans semi-bold");
          var el4 = dom.createTextNode("BOM Summary");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          	\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1, 1]);
          var element12 = dom.childAt(element11, [1]);
          var element13 = dom.childAt(element12, [1, 1]);
          var element14 = dom.childAt(element12, [4, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createElementMorph(element13);
          morphs[1] = dom.createMorphAt(element13, 1, 1);
          morphs[2] = dom.createElementMorph(element14);
          morphs[3] = dom.createMorphAt(element12, 6, 6);
          morphs[4] = dom.createMorphAt(element11, 5, 5);
          morphs[5] = dom.createMorphAt(element11, 7, 7);
          morphs[6] = dom.createMorphAt(element11, 8, 8);
          return morphs;
        },
        statements: [["element", "action", ["openBOMModal", "quoting.bom-modal", ["get", "project", ["loc", [null, [9, 61], [9, 68]]]]], [], ["loc", [null, [9, 17], [9, 70]]]], ["block", "if", [["get", "project.bomDetails.isKittedBom", ["loc", [null, [10, 22], [10, 52]]]]], [], 0, 1, ["loc", [null, [10, 16], [14, 23]]]], ["element", "action", ["newBom"], [], ["loc", [null, [18, 35], [18, 54]]]], ["block", "if", [["get", "project.bomDetails.isKittedBom", ["loc", [null, [20, 18], [20, 48]]]]], [], 2, null, ["loc", [null, [20, 12], [23, 19]]]], ["inline", "bom-summary-text", [], ["class", "mt10", "project", ["subexpr", "@mut", [["get", "project", ["loc", [null, [26, 50], [26, 57]]]]], [], []]], ["loc", [null, [26, 10], [26, 59]]]], ["block", "if", [["get", "project.customPartsQuote", ["loc", [null, [27, 16], [27, 40]]]]], [], 3, null, ["loc", [null, [27, 10], [29, 17]]]], ["block", "unless", [["get", "project.bomDetails.isKittedBom", ["loc", [null, [30, 20], [30, 50]]]]], [], 4, null, ["loc", [null, [30, 10], [32, 21]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 6
              },
              "end": {
                "line": 41,
                "column": 6
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "padded-lr mb20 userSkippedPartsCopy");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "semi-bold");
            var el3 = dom.createTextNode("Our team must review your quote request because your Bill of Materials wasn't uploaded. Please proceed through checkout, and we will provide you with a formal quote. If you have questions, start a chat with us!");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" OR, you can ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            var el4 = dom.createTextNode("upload your BOM here");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(".");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1, 1, 3]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element9);
            return morphs;
          },
          statements: [["element", "action", ["showBomUpload"], [], ["loc", [null, [39, 262], [39, 288]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 6
                },
                "end": {
                  "line": 46,
                  "column": 6
                }
              },
              "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "padded-lr mb20 userRemovedPartsCopy");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "semi-bold");
              var el3 = dom.createTextNode("Ok, you've chosen to provide your own parts.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "mt20");
              var el3 = dom.createTextNode("We maintain rigorous standards for handling and assembling parts.  To make sure your parts aren't damaged in transit, and to ensure the fastest assembly possible, please review our ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" when packing your parts for shipment.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "link-to", ["best practices", "resource-center.tutorials.packing-parts"], ["target", "_blank"], ["loc", [null, [44, 207], [44, 293]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 14
                  },
                  "end": {
                    "line": 58,
                    "column": 14
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Yes, please quote some/all of my parts\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 14
                  },
                  "end": {
                    "line": 65,
                    "column": 14
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                No, I will supply all of my parts to you\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 82,
                    "column": 12
                  },
                  "end": {
                    "line": 86,
                    "column": 12
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "padded-10-20");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("p");
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "fa fa-spin fa-spinner mr10");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("Uploading...");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 12
                  },
                  "end": {
                    "line": 91,
                    "column": 12
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "padded-10-20");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("p");
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "fa fa-spin fa-spinner mr10");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("Parsing...");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 98,
                    "column": 42
                  },
                  "end": {
                    "line": 98,
                    "column": 132
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Do BOMs Right");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 6
                },
                "end": {
                  "line": 106,
                  "column": 6
                }
              },
              "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "padded-lr mb40");
              var el2 = dom.createTextNode("\n         \n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h4");
              dom.setAttribute(el2, "class", "mb20 semi-bold");
              var el3 = dom.createTextNode("First, let's take a look at your Bill of Materials (BOM).");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "padded mb20 bg-lightest-grey borderRadius5");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "class", "h6 block bold");
              var el4 = dom.createTextNode("\n              Should we quote parts for you?\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "class", "radio-inline");
              dom.setAttribute(el3, "style", "margin-bottom: 5px");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("br");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "class", "radio-inline");
              dom.setAttribute(el3, "style", "margin-bottom: 5px");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "alert alert-info flex alignItemsCenter ");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "glyphicon glyphicon-info-sign mr10 font-18");
              dom.setAttribute(el3, "aria-hidden", "true");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              var el4 = dom.createTextNode("Whether you are sending us all or some of your parts OR we are quoting them for you, please upload your BOM for accurate assembly quote and parts placements. ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          \n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "mt20");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "faux-button-upload");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment(" this is a fake button for styling purposes, the action takes place in bom-file-upload ");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4, "id", "fake-file-input");
              dom.setAttribute(el4, "class", "btn btn-blue pointer");
              var el5 = dom.createTextNode("Choose File");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "padded-10-20 ib pointer");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "fileName");
              var el6 = dom.createTextNode("No file chosen");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "small mt20");
              var el3 = dom.createTextNode("* Maximum upload size is 100 mb");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "small");
              var el3 = dom.createTextNode("* File needs to be in an .xls or .xlsx file format.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "small");
              var el3 = dom.createTextNode("* If exporting from a non-Microsoft application, the .xls format may work best.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "small");
              var el3 = dom.createTextNode("* Do not zip your BOM file.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "small");
              var el3 = dom.createTextNode("* Refer to our ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" page for best practices.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "small");
              var el3 = dom.createTextNode("* ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "assets/SC_Sample_BOM.xls");
              dom.setAttribute(el3, "target", "_blank");
              dom.setAttribute(el3, "class", "underline");
              var el4 = dom.createTextNode("Download a Sample BOM template");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(".");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "small");
              var el3 = dom.createTextNode("* Refer to our ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "assets/pdfs/BOMLoadTipsandTricks.pdf");
              dom.setAttribute(el3, "target", "_blank");
              dom.setAttribute(el3, "class", "underline");
              var el4 = dom.createTextNode("BOM Load Tips and Tricks");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" page for help with using our BOM load tool.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h5");
              dom.setAttribute(el2, "class", "mt40");
              var el3 = dom.createTextNode("Don't have a BOM file ready?");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "mt10");
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "role", "button");
              dom.setAttribute(el3, "class", "underline skipOnlineQuoting");
              var el4 = dom.createTextNode("Skip online");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" That's okay! Once a member of our customer service team starts processing your quote, they will reach out to you and request your BOM.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [3]);
              var element7 = dom.childAt(element5, [7]);
              var element8 = dom.childAt(element5, [25, 0]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [7]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]), 7, 7);
              morphs[3] = dom.createMorphAt(element7, 3, 3);
              morphs[4] = dom.createMorphAt(element7, 4, 4);
              morphs[5] = dom.createMorphAt(dom.childAt(element5, [17]), 1, 1);
              morphs[6] = dom.createElementMorph(element8);
              return morphs;
            },
            statements: [["block", "radio-button", [], ["name", "isSCQuotingParts", "value", true, "groupValue", ["subexpr", "@mut", [["get", "isSCQuotingParts", ["loc", [null, [55, 76], [55, 92]]]]], [], []], "changed", ""], 0, null, ["loc", [null, [55, 14], [58, 31]]]], ["block", "radio-button", [], ["name", "isSCQuotingParts", "value", false, "groupValue", ["subexpr", "@mut", [["get", "isSCQuotingParts", ["loc", [null, [62, 77], [62, 93]]]]], [], []], "changed", "", "action", "userProvideParts"], 1, null, ["loc", [null, [62, 14], [65, 31]]]], ["inline", "bom-file-upload", [], ["project", ["subexpr", "@mut", [["get", "project", ["loc", [null, [80, 40], [80, 47]]]]], [], []], "component", ["subexpr", "@mut", [["get", "this", ["loc", [null, [80, 58], [80, 62]]]]], [], []], "register-as", ["subexpr", "@mut", [["get", "bomFileUploadComponent", ["loc", [null, [80, 75], [80, 97]]]]], [], []], "isUserSourcingBom", ["subexpr", "@mut", [["get", "isUserSourcingBom", ["loc", [null, [80, 116], [80, 133]]]]], [], []]], ["loc", [null, [80, 14], [80, 135]]]], ["block", "liquid-if", [["get", "showSpinner", ["loc", [null, [82, 25], [82, 36]]]]], ["class", "fade-transition"], 2, null, ["loc", [null, [82, 12], [86, 26]]]], ["block", "liquid-if", [["get", "showParsingSpinner", ["loc", [null, [87, 25], [87, 43]]]]], ["class", "fade-transition"], 3, null, ["loc", [null, [87, 12], [91, 26]]]], ["block", "link-to", ["resource-center.tips.do-boms"], ["target", "_blank", "class", "underline"], 4, null, ["loc", [null, [98, 42], [98, 144]]]], ["element", "action", ["skipOnlineParts"], [], ["loc", [null, [103, 43], [103, 71]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 6
              },
              "end": {
                "line": 106,
                "column": 6
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "project.userRemovedParts", ["loc", [null, [41, 16], [41, 40]]]]], [], 0, 1, ["loc", [null, [41, 6], [106, 6]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 4
            },
            "end": {
              "line": 107,
              "column": 4
            }
          },
          "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "project.userSkippedOnlineQuoting", ["loc", [null, [37, 12], [37, 44]]]]], [], 0, 1, ["loc", [null, [37, 6], [106, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 113,
              "column": 8
            },
            "end": {
              "line": 116,
              "column": 8
            }
          },
          "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "open-sans semi-bold");
          var el2 = dom.createTextNode("Ok, you've chosen to provide your own parts.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "mt10");
          var el2 = dom.createTextNode("We maintain rigorous standards for handling and assembling parts.  To make sure your parts aren't damaged in transit, and to ensure the fastest assembly possible, please review our ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" when packing your parts for shipment.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "link-to", ["best practices", "resource-center.tutorials.packing-parts"], ["target", "_blank"], ["loc", [null, [115, 207], [115, 293]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 118,
              "column": 8
            },
            "end": {
              "line": 121,
              "column": 8
            }
          },
          "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1, "class", "open-sans mt10");
          var el2 = dom.createTextNode("You cannot upload or edit the BOM from a mobile device.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "mt20");
          var el2 = dom.createTextNode("Access this quote with a non mobile device if you need to upload a BOM and source your parts.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 129,
                "column": 14
              },
              "end": {
                "line": 131,
                "column": 14
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "mt10");
            var el2 = dom.createTextNode("We'll get back to you with parts pricing after you request a formal quote during checkout.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 124,
              "column": 8
            },
            "end": {
              "line": 134,
              "column": 8
            }
          },
          "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bordered bg-white mt20");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "padded");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          dom.setAttribute(el3, "class", "open-sans semi-bold");
          var el4 = dom.createTextNode("BOM Summary");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element4, 3, 3);
          morphs[1] = dom.createMorphAt(element4, 5, 5);
          return morphs;
        },
        statements: [["inline", "bom-summary-text", [], ["class", "mt10", "project", ["subexpr", "@mut", [["get", "project", ["loc", [null, [128, 54], [128, 61]]]]], [], []]], ["loc", [null, [128, 14], [128, 63]]]], ["block", "if", [["get", "project.customPartsQuote", ["loc", [null, [129, 20], [129, 44]]]]], [], 0, null, ["loc", [null, [129, 14], [131, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 139,
              "column": 6
            },
            "end": {
              "line": 147,
              "column": 6
            }
          },
          "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "btn btn-primary float-left sm-hide customQuoteBtn");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-arrow-down mr5");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Continue to Custom Quote\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" second button shows for mobile and automatically skips parts and moves to custom quote ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "btn btn-primary float-left none sm-show mobileSkipPartsButton");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-arrow-down mr5");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Continue to Custom Quote\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [5]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["element", "action", ["saveAndPerformAction", "toggleComponent", "customQuote", "parts"], [], ["loc", [null, [140, 71], [140, 144]]]], ["element", "action", ["skipPartsAndContinue"], [], ["loc", [null, [144, 83], [144, 116]]]]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 152,
                    "column": 14
                  },
                  "end": {
                    "line": 154,
                    "column": 14
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "id", "update");
                var el2 = dom.createTextNode("Continue");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 154,
                    "column": 14
                  },
                  "end": {
                    "line": 156,
                    "column": 14
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "id", "update");
                var el2 = dom.createTextNode("Update");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 151,
                  "column": 12
                },
                "end": {
                  "line": 157,
                  "column": 12
                }
              },
              "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "isMobile", ["loc", [null, [152, 20], [152, 28]]]]], [], 0, 1, ["loc", [null, [152, 14], [156, 21]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 158,
                    "column": 14
                  },
                  "end": {
                    "line": 160,
                    "column": 14
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "id", "update");
                var el2 = dom.createTextNode("Continue");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 160,
                    "column": 14
                  },
                  "end": {
                    "line": 162,
                    "column": 14
                  }
                },
                "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "id", "continue");
                var el2 = dom.createTextNode("Continue to assembly");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 157,
                  "column": 12
                },
                "end": {
                  "line": 163,
                  "column": 12
                }
              },
              "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "isMobile", ["loc", [null, [158, 20], [158, 28]]]]], [], 0, 1, ["loc", [null, [158, 14], [162, 21]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 148,
                "column": 8
              },
              "end": {
                "line": 165,
                "column": 8
              }
            },
            "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-arrow-down mr5");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["btn btn-primary float-left partsSubmitBtn ", ["subexpr", "if", [["get", "project.partsIncludedBoolean", ["loc", [null, [149, 69], [149, 97]]]], "sm-show", "sm-hide"], [], ["loc", [null, [149, 64], [149, 119]]]]]]], ["element", "action", ["saveAndPerformAction", "toggleComponent", "assembly", "parts"], [], ["loc", [null, [149, 121], [149, 191]]]], ["block", "if", [["get", "isEditing", ["loc", [null, [151, 18], [151, 27]]]]], [], 0, 1, ["loc", [null, [151, 12], [163, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 147,
              "column": 6
            },
            "end": {
              "line": 171,
              "column": 6
            }
          },
          "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" second button shows for mobile and automatically skips parts so that project can be submitted ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-arrow-down mr5");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          Continue\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [4]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createAttrMorph(element1, 'class');
          morphs[2] = dom.createElementMorph(element1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "ableToContinuePricing", ["loc", [null, [148, 14], [148, 35]]]]], [], 0, null, ["loc", [null, [148, 8], [165, 15]]]], ["attribute", "class", ["concat", ["btn btn-primary partsSubmitBtn float-left none ", ["subexpr", "if", [["get", "project.partsIncludedBoolean", ["loc", [null, [167, 72], [167, 100]]]], "sm-hide", "sm-show"], [], ["loc", [null, [167, 67], [167, 122]]]]]]], ["element", "action", ["skipPartsAndContinue"], [], ["loc", [null, [167, 124], [167, 157]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 177,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/quote-parts.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-12");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "sm-hide");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" end mobile hide ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" mobile quote parts section ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "none sm-show");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "mb20 padded-lr ib");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" end mobile parts section ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "mt20 mb20 padded-lr ib");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" end col-md-7 ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0]);
        var element16 = dom.childAt(element15, [1]);
        var element17 = dom.childAt(element16, [7, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element15, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element16, [1]), 1, 1);
        morphs[2] = dom.createMorphAt(element17, 1, 1);
        morphs[3] = dom.createMorphAt(element17, 3, 3);
        morphs[4] = dom.createMorphAt(element17, 5, 5);
        morphs[5] = dom.createMorphAt(dom.childAt(element16, [11]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["subexpr", "unless", [["get", "showParts", ["loc", [null, [1, 21], [1, 30]]]], "none"], [], ["loc", [null, [1, 12], [1, 39]]]], " row partsComponent"]]], ["block", "if", [["get", "summaryActive", ["loc", [null, [4, 10], [4, 23]]]]], [], 0, 1, ["loc", [null, [4, 4], [107, 11]]]], ["block", "if", [["get", "project.userRemovedParts", ["loc", [null, [113, 14], [113, 38]]]]], [], 2, null, ["loc", [null, [113, 8], [116, 15]]]], ["block", "unless", [["get", "project.userRemovedParts", ["loc", [null, [118, 18], [118, 42]]]]], [], 3, null, ["loc", [null, [118, 8], [121, 19]]]], ["block", "if", [["get", "showPartsSummary", ["loc", [null, [124, 14], [124, 30]]]]], [], 4, null, ["loc", [null, [124, 8], [134, 15]]]], ["block", "if", [["get", "project.isCustomQuote", ["loc", [null, [139, 12], [139, 33]]]]], [], 5, 6, ["loc", [null, [139, 6], [171, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});